<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="adtv">
            Latest Videos
          </h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mainVid mobHide" lg="7">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="
              'https://www.youtube.com/embed/' +
                latest.items[0].contentDetails.videoId
            "
            allowfullscreen
          >
          </b-embed>
        </b-col>
        <b-col>
          <b-row>
            <b-col
              class="col-md-6 col-sm-6 col-12"
              v-for="(gallery, index) in latest.items.slice(0, 4)"
              :key="index"
            >
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="
                  'https://www.youtube.com/embed/' +
                    gallery.contentDetails.videoId
                "
                allowfullscreen
              ></b-embed>
              <h4 class="videoText">Video</h4>
              <p class="galleryTitle">
                {{ gallery.snippet.title }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="adtv">
            Tournament Highlights
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mainVid mobHide" lg="7">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="
              'https://www.youtube.com/embed/' +
                highlights.items[0].contentDetails.videoId
            "
            allowfullscreen
          >
          </b-embed>
        </b-col>
        <b-col>
          <b-row>
            <b-col
              class="col-md-6 col-sm-6 col-12"
              v-for="(gallery, index) in highlights.items.slice(0, 4)"
              :key="index"
            >
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="
                  'https://www.youtube.com/embed/' +
                    gallery.contentDetails.videoId
                "
                allowfullscreen
              ></b-embed>
              <p class="galleryTitle">
                {{ gallery.snippet.title }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="adtv">
            #ATPranks
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mainVid mobHide" lg="7">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="
              'https://www.youtube.com/embed/' +
                pranks.items[0].contentDetails.videoId
            "
            allowfullscreen
          >
          </b-embed>
        </b-col>
        <b-col>
          <b-row>
            <b-col
              class="col-md-6 col-sm-6 col-12"
              v-for="(gallery, index) in pranks.items.slice(0, 4)"
              :key="index"
            >
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="
                  'https://www.youtube.com/embed/' +
                    gallery.contentDetails.videoId
                "
                allowfullscreen
              ></b-embed>
              <p class="galleryTitle">
                {{ gallery.snippet.title }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="adtv">
            Players Features
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mainVid mobHide" lg="7">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="
              'https://www.youtube.com/embed/' +
                feature.items[0].contentDetails.videoId
            "
            allowfullscreen
          >
          </b-embed>
        </b-col>
        <b-col>
          <b-row>
            <b-col
              class="col-md-6 col-sm-6 col-12"
              v-for="(gallery, index) in feature.items.slice(0, 4)"
              :key="index"
            >
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="
                  'https://www.youtube.com/embed/' +
                    gallery.contentDetails.videoId
                "
                allowfullscreen
              ></b-embed>
              <p class="galleryTitle">
                {{ gallery.snippet.title }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "youTube",
  props:['config'],
  data() {
    return {
      feature: [],
      pranks: [],
      highlights: [],
      latest: [],
      text: "",
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_YOUTUBE_URL +
          process.env.VUE_APP_YOUTUBE_PLAYLIST_1 +
          "&key=" +
          process.env.VUE_APP_YOUTUBE_KEY
      )
      .then((response) => {
        this.latest = response.data;
        return axios.get(
          process.env.VUE_APP_YOUTUBE_URL +
            process.env.VUE_APP_YOUTUBE_PLAYLIST_2 +
            "&key=" +
            process.env.VUE_APP_YOUTUBE_KEY
        );
      })
      .then((response) => {
        this.highlights = response.data;
        return axios.get(
          process.env.VUE_APP_YOUTUBE_URL +
            process.env.VUE_APP_YOUTUBE_PLAYLIST_3 +
            "&key=" +
            process.env.VUE_APP_YOUTUBE_KEY
        );
      })
      .then((response) => {
        this.pranks = response.data;
        return axios.get(
          process.env.VUE_APP_YOUTUBE_URL +
            process.env.VUE_APP_YOUTUBE_PLAYLIST_4 +
            "&key=" +
            process.env.VUE_APP_YOUTUBE_KEY
        );
      })
      .then((response) => {
        this.feature = response.data;
      });
  },
};
</script>

<style scoped>
h4.videoText {
  font-size: 10px;
  color: #A8B7C9;
  padding-top: 10px;
  text-transform: uppercase;
}
.row {
  padding-bottom: 20px;
}
.row:first-child {
  padding-bottom: 5px;
}
.adtv {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
}
.galleryTitle {
  font-size: 14px !important;
  line-height: 101% !important;
  padding: 0px 0px 0px 0px !important;
  color: #0A3F7F;
  font-weight: 500;
}
.mainVid {
  /* padding-top: 35px; */
}
.seachInput {
  width: 25%;
  display: inline-block;
  margin-top: 1em;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  padding: 5px 11px;
  border-radius: 5px;
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 0px;
  margin-right: 15px;
  margin-bottom: 3em;
}
.searchBoxbutton {
  margin-left: -30px;
}
@media only screen and (max-width: 768px) {
  .col {
    flex-basis: 100%;
    flex-grow: 1;
    max-width: 100%;
    padding-bottom: 13px;
  }
  .mobHide {
    display: none;
  }
@media only screen and (max-width: 480px) {
  .logo{
    float: none;
    margin: 0;
    padding-top: 15px;
  }
}

}
</style>
