var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"adtv"},[_vm._v(" Latest Videos ")])])],1),_c('b-row',[_c('b-col',{staticClass:"mainVid mobHide",attrs:{"lg":"7"}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
              _vm.latest.items[0].contentDetails.videoId,"allowfullscreen":""}})],1),_c('b-col',[_c('b-row',_vm._l((_vm.latest.items.slice(0, 4)),function(gallery,index){return _c('b-col',{key:index,staticClass:"col-md-6 col-sm-6 col-12"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                  gallery.contentDetails.videoId,"allowfullscreen":""}}),_c('h4',{staticClass:"videoText"},[_vm._v("Video")]),_c('p',{staticClass:"galleryTitle"},[_vm._v(" "+_vm._s(gallery.snippet.title)+" ")])],1)}),1)],1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"adtv"},[_vm._v(" Tournament Highlights ")])])],1),_c('b-row',[_c('b-col',{staticClass:"mainVid mobHide",attrs:{"lg":"7"}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
              _vm.highlights.items[0].contentDetails.videoId,"allowfullscreen":""}})],1),_c('b-col',[_c('b-row',_vm._l((_vm.highlights.items.slice(0, 4)),function(gallery,index){return _c('b-col',{key:index,staticClass:"col-md-6 col-sm-6 col-12"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                  gallery.contentDetails.videoId,"allowfullscreen":""}}),_c('p',{staticClass:"galleryTitle"},[_vm._v(" "+_vm._s(gallery.snippet.title)+" ")])],1)}),1)],1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"adtv"},[_vm._v(" #ATPranks ")])])],1),_c('b-row',[_c('b-col',{staticClass:"mainVid mobHide",attrs:{"lg":"7"}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
              _vm.pranks.items[0].contentDetails.videoId,"allowfullscreen":""}})],1),_c('b-col',[_c('b-row',_vm._l((_vm.pranks.items.slice(0, 4)),function(gallery,index){return _c('b-col',{key:index,staticClass:"col-md-6 col-sm-6 col-12"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                  gallery.contentDetails.videoId,"allowfullscreen":""}}),_c('p',{staticClass:"galleryTitle"},[_vm._v(" "+_vm._s(gallery.snippet.title)+" ")])],1)}),1)],1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"adtv"},[_vm._v(" Players Features ")])])],1),_c('b-row',[_c('b-col',{staticClass:"mainVid mobHide",attrs:{"lg":"7"}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
              _vm.feature.items[0].contentDetails.videoId,"allowfullscreen":""}})],1),_c('b-col',[_c('b-row',_vm._l((_vm.feature.items.slice(0, 4)),function(gallery,index){return _c('b-col',{key:index,staticClass:"col-md-6 col-sm-6 col-12"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                  gallery.contentDetails.videoId,"allowfullscreen":""}}),_c('p',{staticClass:"galleryTitle"},[_vm._v(" "+_vm._s(gallery.snippet.title)+" ")])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }